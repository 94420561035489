import React from 'react'
import PropTypes from 'prop-types'

import TeamItem from './TeamItem'
import TestimonialItem from './TestimonialItem'

const TeamList = (props) => {
  const { team, testimonials, filter } = props
  let teamToDisplay = team
  let hasTestimonial = false
  let testimonialToDisplay = []
  let testimonialEmployee = []
  if (filter !== false) {
    teamToDisplay = team.filter((employee) => employee.acf.details.category.indexOf(filter) !== -1)
    testimonialToDisplay = testimonials.filter(
      (testimonial) => testimonial.acf['employee-category'] === filter
    )
  } else {
    testimonialToDisplay = testimonials.filter(
      (testimonial) => testimonial.acf['show-on-overview'] === true
    )
  }

  if (testimonialToDisplay.length) {
    hasTestimonial = true
    testimonialEmployee = team.filter(
      (employee) => employee.id === testimonialToDisplay[0].acf.employee
    )
  }

  const showAfterHowManyItems = teamToDisplay.length <= 8 ? 4 : 8
  return (
    <>
      {teamToDisplay.map((employee, index) => {
        return (
          <React.Fragment key={employee.id}>
            {hasTestimonial && index === showAfterHowManyItems && (
              <TestimonialItem
                key={employee.id * 2}
                employee={testimonialEmployee}
                testimonial={testimonialToDisplay}
              />
            )}
            <TeamItem key={employee.id} employee={employee} className='employee' />
          </React.Fragment>
        )
      })}
    </>
  )
}

TeamList.propTypes = {
  team: PropTypes.array.isRequired,
  testimonials: PropTypes.array.isRequired,
  filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

TeamList.defaultProps = {
  filter: false
}

export default TeamList

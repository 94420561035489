import '@babel/polyfill'

import '../styles/main.scss'

import hoverintent from 'hoverintent'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import tippy from 'tippy.js'

import './components/ReferencesOverview'
import './components/TeamOverview'

import { trans } from './components/helpers/Language'

if (module.hot) module.hot.accept()

document.addEventListener('DOMContentLoaded', function DOMContentLoaded() {
  // Detect touch device
  //----------------------------------------------------------
  const isTouch = 'ontouchstart' in document.documentElement !== false

  // Header dropdown menus
  //----------------------------------------------------------
  const menuElements = [...document.querySelectorAll('.header-menu .menu-item-has-children')]
  const menuLinkElements = [
    ...document.querySelectorAll('.header-menu .menu-item-has-children > a')
  ]
  const openSubmenu = (element) => {
    element.classList.add('submenu-is-open')
  }
  const closeSubmenu = (element) => {
    element.classList.remove('submenu-is-open')
  }

  // Regular devices
  const opts = {
    timeout: 250,
    interval: 50
  }

  if (!isTouch) {
    document.body.classList.add('no-touch')
    menuElements.forEach((element) => {
      hoverintent(
        element,
        () => {
          openSubmenu(element)
        },
        () => {
          closeSubmenu(element)
        }
      ).options(opts)
    })
    // Prevent links on parents
    menuLinkElements.forEach((element) => {
      element.addEventListener('click', function menuLinkParentClick(event) {
        event.preventDefault()
      })
    })
  }

  // Touch devices
  if (isTouch) {
    document.body.classList.add('touch')
    menuLinkElements.forEach((element) => {
      element.addEventListener('click', function menuLinkClick(event) {
        menuElements.forEach((node) => {
          node.classList.remove('submenu-is-open')
        })
        element.parentNode.classList.add('submenu-is-open')
        event.preventDefault()
      })
    })
  }

  // Mobile menu
  //----------------------------------------------------------
  const mobileMenu = document.getElementById('mobile-menu')
  const openMobileMenuButton = document.getElementById('open-mobile-menu')
  const closeMobileMenuButton = document.getElementById('close-mobile-menu')
  openMobileMenuButton.addEventListener(
    'touchstart',
    function openMobileMenu(event) {
      mobileMenu.classList.add('mobile-menu-is-open')
      disableBodyScroll(mobileMenu)
      event.preventDefault()
    },
    { passive: false }
  )
  closeMobileMenuButton.addEventListener(
    'touchstart',
    function closeMobileMenu(event) {
      mobileMenu.classList.remove('mobile-menu-is-open')
      enableBodyScroll(mobileMenu)
      event.preventDefault()
    },
    { passive: false }
  )

  // Mobile dropdown menu
  //----------------------------------------------------------
  const mobileDropdownItems = [
    ...document.querySelectorAll('.mobile-menu .menu-item-has-children > a')
  ]
  const handleMobileDropdown = (event) => {
    const listItem = event.currentTarget.parentNode
    if (listItem.classList.contains('submenu-is-open')) {
      listItem.classList.remove('submenu-is-open')
    } else {
      mobileDropdownItems.forEach((item) => {
        item.parentNode.classList.remove('submenu-is-open')
      })
      listItem.classList.add('submenu-is-open')
    }
    event.preventDefault()
  }
  mobileDropdownItems.forEach((link) => {
    link.addEventListener('touchstart', handleMobileDropdown, { passive: false })
  })

  // Mobile category selector
  //----------------------------------------------------------
  const categoriesMenu = document.getElementById('sidebar-header')
  const toggleCategoriesButton = document.getElementById('open-mobile-shop-categories')
  if (toggleCategoriesButton) {
    toggleCategoriesButton.addEventListener(
      'touchstart',
      function toggleCategoriesMenu(event) {
        toggleCategoriesButton.classList.toggle('active')
        categoriesMenu.classList.toggle('shop-categories-is-open')
        event.preventDefault()
      },
      { passive: false }
    )
  }

  // Tooltips
  //----------------------------------------------------------
  // General tooltips by html attribute data-tippy-content
  tippy('[data-tippy-content]')

  // Add tooltip to add to cart button on product archives
  tippy(
    document.querySelectorAll(
      '.product-actions .add_to_cart_button.product_type_simple, .vartable .single_add_to_cart_button'
    ),
    {
      content: trans('add_to_cart')
    }
  )

  // Add tooltip to view details button on product archives
  tippy(document.querySelectorAll('.product-actions .add_to_cart_button.product_type_variable'), {
    content: trans('show_details')
  })

  // Add tooltip to add to merkliste
  tippy(document.querySelectorAll('.tinvwl_add_to_wishlist_button'), {
    content: trans('add_to_wishlist')
  })

  // Tooltips for Staffelpreise
  // This is now a very nice solution, but I didn't find a better one :(
  //----------------------------------------------------------
  const pricesStorage = document.getElementById('tier-prices-storage')
  const variationsTableRow = document.querySelectorAll(
    '.single-product .product-type-variable .vartable tbody tr'
  )
  if (pricesStorage && variationsTableRow.length > 0) {
    variationsTableRow.forEach((variation) => {
      const variationID = variation.classList[0] // Get first class, which should be variation id
      const priceStorageElement = pricesStorage.querySelector(`div[data-id="${variationID}"`) // Get corresponding element from price storage element
      if (priceStorageElement) {
        const wcb2bTierPrices = priceStorageElement.querySelector('.wcb2b-tier_prices')
        if (wcb2bTierPrices) {
          if (wcb2bTierPrices.getElementsByTagName('li').length >= 1) {
            // Check if there are actually any tier prices for this variation
            const priceInfoDiv = document.createElement('i')
            priceInfoDiv.innerHTML = trans('tier_prices')
            priceInfoDiv.classList.add('tier-prices-tooltip')
            variation.querySelector('.pricecol').appendChild(priceInfoDiv)
            tippy(priceInfoDiv, {
              content: wcb2bTierPrices
            })

            const gvgIcon = document.createElement('span')
            gvgIcon.innerHTML = trans('gvg_group_buy')
            gvgIcon.classList.add('gvg-category-icon')
            variation.querySelector('.skucol').appendChild(gvgIcon)
          } else {
            const hasSalePrice = variation.querySelector('.pricecol del') !== null
            if (hasSalePrice) {
              const gvgIcon = document.createElement('span')
              gvgIcon.innerHTML = trans('gvg_group_buy')
              gvgIcon.classList.add('gvg-category-icon')
              variation.querySelector('.skucol').appendChild(gvgIcon)
            }
          }
        }
      } else {
        const hasSalePrice = variation.querySelector('.pricecol del') !== null
        if (hasSalePrice) {
          const gvgIcon = document.createElement('span')
          gvgIcon.innerHTML = trans('gvg_group_buy')
          gvgIcon.classList.add('gvg-category-icon')
          variation.querySelector('.skucol').appendChild(gvgIcon)
        }
      }
    })
  }
  const productToDisplayGVGIcon = document.querySelector('.display-gvg-icon')
  if (productToDisplayGVGIcon && variationsTableRow.length > 0) {
    variationsTableRow.forEach((variation) => {
      console.log(variation)
      const gvgIcon = document.createElement('span')
      gvgIcon.innerHTML = trans('gvg_group_buy')
      gvgIcon.classList.add('gvg-category-icon')
      variation.querySelector('.skucol').appendChild(gvgIcon)
    })
  }
})

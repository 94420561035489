export const languageCode = window.currentLanguageCode || 'de'

export function trans(message, variables) {
  return (window.objectL10n[message] || message).replace(/{(\w+)}/g, function (
    match,
    variableName
  ) {
    return Object.prototype.hasOwnProperty.call(variables, variableName)
      ? variables[variableName]
      : match
  })
}

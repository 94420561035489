import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'

import 'isomorphic-fetch'

import Error from './helpers/Error'
import Loader from './helpers/Loader'
import ReferencesList from './ReferencesList'
import { languageCode } from './helpers/Language'

const ReferencesOverview = () => {
  const [hasError, setErrors] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isLoadingMore, setLoadingMore] = useState(false)
  const [references, setReferences] = useState()
  const [filter, setFilter] = useState(false)
  const [limit, setLimit] = useState(6)
  const [length, setLength] = useState()

  const apiUrl = `/wp-json/wp/v2/reference/?per_page=100&orderby=menu_order&order=asc&lang=${languageCode}`

  const loadData = () => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setReferences(data)
        setLength(data.length)
        setLoading(false)
      })
      .catch((error) => setErrors(error))
  }

  const handleFilterButtonClick = useCallback((event) => {
    const { target } = event
    const filterButtons = [...document.querySelectorAll('.references-filter-button')]
    filterButtons.forEach((button) => {
      button.classList.remove('active')
    })
    target.classList.add('active')
    const filterAttribute = target.getAttribute('data-category')
    if (filterAttribute !== false && filterAttribute !== 'false') {
      const referencesCategory = parseInt(filterAttribute, 10)
      setFilter(referencesCategory)
    } else {
      setFilter(false)
    }
    event.preventDefault()
  }, [])

  useEffect(() => {
    loadData()

    const filterButtons = [...document.querySelectorAll('.references-filter-button')]
    filterButtons.forEach((button) => {
      button.addEventListener('click', handleFilterButtonClick)
    })

    return () => {
      filterButtons.forEach((button) => {
        button.removeEventListener('click', handleFilterButtonClick)
      })
    }
  }, [handleFilterButtonClick])

  const handleScroll = () => {
    const elementPosition = document.getElementById('referencesOverview').getBoundingClientRect()
    const positionFromTop = elementPosition.bottom + window.scrollY
    const scrolledTarget = window.innerHeight + window.pageYOffset - 300

    if (limit < length) {
      if (positionFromTop > scrolledTarget && !isLoadingMore) {
        setLoadingMore(true)
        setTimeout(() => {
          setLimit(limit + 3)
          setLoadingMore(false)
        }, 1000)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll, isLoadingMore, limit, length])

  return (
    <>
      {hasError && <Error />}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ReferencesList references={references} filter={filter} limit={limit} />
          {isLoadingMore && <Loader />}
        </>
      )}
    </>
  )
}

const domContainer = document.getElementById('referencesOverview')
if (domContainer) {
  ReactDOM.render(<ReferencesOverview />, domContainer)
}

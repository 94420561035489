import React from 'react'
import PropTypes from 'prop-types'

const TestimonialItem = (props) => {
  const { employee, testimonial } = props
  if (!employee.length || !testimonial.length) {
    return null
  }
  const { quote } = testimonial[0].acf
  const { image, details } = employee[0].acf

  const style = {
    backgroundImage: image ? `url(${image.sizes.teaser})` : ''
  }

  return (
    <div className='testimonial'>
      {image && <div className='testimonial__image' style={style} />}
      <div className='testimonial__content'>
        <p className='testimonial__quote'>&laquo;{quote}&raquo;</p>
        <p className='testimonial__name'>{details.name}</p>
        {details.description && <p className='testimonial__description'>{details.description}</p>}
      </div>
    </div>
  )
}

TestimonialItem.propTypes = {
  employee: PropTypes.array.isRequired,
  testimonial: PropTypes.array.isRequired
}

export default TestimonialItem

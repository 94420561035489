import React from 'react'
import PropTypes from 'prop-types'

import ReferencesItem from './ReferencesItem'

const ReferencesList = (props) => {
  const { references, filter, limit } = props
  let referencesToDisplay = references
  if (filter !== false) {
    referencesToDisplay = references.filter(
      (reference) => reference.acf.details.category.term_id === filter
    )
  }
  return (
    <>
      {referencesToDisplay.slice(0, limit).map((reference) => (
        <ReferencesItem key={reference.id} reference={reference} className='reference' />
      ))}
    </>
  )
}

ReferencesList.propTypes = {
  references: PropTypes.array.isRequired,
  filter: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  limit: PropTypes.number.isRequired
}

ReferencesList.defaultProps = {
  filter: false
}

export default ReferencesList

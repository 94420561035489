import React from 'react'
import PropTypes from 'prop-types'
import { trans } from './helpers/Language'

const ReferencesItem = (props) => {
  const { reference } = props
  return (
    <div className='reference'>
      {reference.acf.image && (
        <img
          className='reference__image'
          src={reference.acf.image.sizes.teaser}
          width={reference.acf.image.sizes['teaser-width']}
          height={reference.acf.image.sizes['teaser-height']}
          alt={reference.title.rendered}
          title={reference.title.rendered}
        />
      )}
      <h2>{reference.title.rendered}</h2>
      {reference.acf.details.category && (
        <p className='reference__category'>
          {reference.acf.details.category.name.replace(/&amp;/gi, '&')}
        </p>
      )}
      {reference.acf.details.location && (
        <p className='reference__location'>{reference.acf.details.location}</p>
      )}
      {reference.acf.details.description && (
        <p className='reference__description'>{reference.acf.details.description}</p>
      )}
      {reference.acf['has-detail-page'] && (
        <a
          className='reference__detail-link button--gray'
          href={reference.link}
          title={reference.title.rendered}>
          {trans('details')}
        </a>
      )}
    </div>
  )
}

ReferencesItem.propTypes = {
  reference: PropTypes.object.isRequired
}

export default ReferencesItem
